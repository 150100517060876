import React, { useEffect, useState } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useQueryParams } from 'hooks/useQueryParam';
import { authSessionThunk, getLoanOffer, getStudentLoanAssistanceData } from 'thunks';
import { trackConversionLead } from 'utils/analytics';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import StateContainer from 'components/StateContainer';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { FlowComponentType } from 'routes/FlowRouter';
import { useNavigationType } from 'hooks/useNavigate';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';
import { getProfessionGroup } from 'selectors/professionGroup';
import { CurrentFlow } from 'enums/CurrentFlow';
import { LoanOfferProgress, LoanOfferStep } from 'api/LoanOfferApi';
import { StudentLoanEligibilityResult as Result } from 'enums/StudentLoanForgivenessFlowResults';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getClientTimezone } from 'utils/dateUtils';
import { setStudentLoanApplicationId } from 'handlers/studentLoanData';

import styles from './Analyzing.module.scss';

const STEPS_READY_FOR_STUDENT_LOANS = [
  LoanOfferStep.RunningDecisionEngine,
  LoanOfferStep.GeneratingDebtProfile,
  LoanOfferStep.CreatingHardOffer,
];

type StudentLoanAssistanceInputData = {
  ready: boolean;
  applicationId?: string;
};

export const getLoadingProgress = (offerProgress: LoanOfferProgress) => {
  let progress;
  switch (offerProgress.step) {
    case LoanOfferStep.ValidatedInput:
      progress = 30;
      break;
    case LoanOfferStep.GettingTradelines:
      progress = 30;
      break;
    case LoanOfferStep.SyncingTradelines:
      if (offerProgress.initialAccounts && offerProgress.syncingAccounts !== undefined) {
        progress = 100 - (50 * offerProgress.syncingAccounts) / offerProgress.initialAccounts;
      } else {
        progress = 30;
      }
      break;
    case LoanOfferStep.RunningDecisionEngine:
    case LoanOfferStep.GeneratingDebtProfile:
    case LoanOfferStep.CreatingHardOffer:
      progress = 100;
      break;
    default:
      progress = 0;
  }
  return progress;
};

const Analyzing = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();
  const navigationType = useNavigationType();

  const [progress, setProgress] = useState(0);

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const {
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
  } = useSelector(getYourIncome);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { entityId, isLoading, cannotRetrieveData } = useSelector(getMethodAuthData);
  const { partnerName } = useSelector(getPreQualificationData);
  const { sessionToken } = useSelector(getAuthData);
  const { isLoading: isLoadingStudentLoanData, fetched: studentLoanDataFetched, eligible } = useSelector(
    getStudentLoanData,
  );
  const [studentLoanAssistanceInput, setStudentLoanAssistanceInput] = useState<StudentLoanAssistanceInputData>({
    ready: false,
  });

  // Create financial checkup application
  useEffect(() => {
    const apply = async () => {
      if (!entityId && isLoading) {
        return;
      }
      if (!entityId && !isLoading) {
        if (!sessionToken) {
          handleNext(Result.Error);
          return;
        }
        dispatchWithUnwrap(
          authSessionThunk({
            phoneNumber,
            firstName,
            lastName,
            sessionToken,
          }),
        );
        return;
      }

      if (cannotRetrieveData) {
        handleNext(Result.CannotRetrieveMethodData);
        return;
      }

      try {
        setProgress(10);

        const offer = await dispatchWithUnwrap(
          getLoanOffer({
            application: {
              firstName,
              lastName,
              professionGroup,
              email,
              phoneNumber,
              entityId,
              partner: partnerName,
              totalAnnualIncome: totalAnnualIncome ?? 0,
              resumeLink: `${window.location.href}`,
            },
            employment: [
              {
                borrower_employer_name: employerName,
                hire_datetime: startOfEmployment!,
              },
            ],
            timezone: getClientTimezone(),
            // StudentLoanForgiveness uses FinancialCheckup flow
            currentFlow: CurrentFlow.FinancialCheckup,
            utm: getUtmTagsVariables(params),
            sessionToken,
            updateCallback: loanOfferUpdateCallback,
          }),
        );

        if (offer.data.borrower_id) {
          analytics.identify(offer.data.borrower_id);
          (window as any).nid('setUserId', offer.data.application_id);
        }

        setStudentLoanAssistanceInput({ ready: true, applicationId: offer.data.application_id });
        dispatch(setStudentLoanApplicationId(offer.data.application_id!));
      } catch (error) {
        handleNext(Result.Error);
        return;
      }

      trackConversionLead({
        email,
        firstName,
        lastName,
        phoneNumber,
      });
    };

    if (navigationType === 'POP') {
      return;
    }
    apply();
  }, [entityId]);

  // When creating a loan offer, we intercept the update callback to determine if application is ready to get student loan assistance
  const loanOfferUpdateCallback = async (data: LoanOfferProgress) => {
    setProgress(getLoadingProgress(data));
    if (data.applicationId && STEPS_READY_FOR_STUDENT_LOANS.includes(data.step)) {
      setStudentLoanAssistanceInput({ ready: true, applicationId: data.applicationId });
    }
  };

  // Fetch student loan assistance data for application
  const fetchStudentLoanAssistanceData = async () => {
    if (studentLoanAssistanceInput.ready && studentLoanAssistanceInput.applicationId) {
      const { applicationId } = studentLoanAssistanceInput;
      await dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId }));
    }
  };

  // Fetch student loan assistance data for application when application data is available
  useEffect(() => {
    if (!isLoadingStudentLoanData && !studentLoanDataFetched && studentLoanAssistanceInput.ready) {
      fetchStudentLoanAssistanceData();
    }
  }, [studentLoanAssistanceInput]);

  useEffect(() => {
    if (eligible !== undefined) {
      handleNext(eligible ? Result.Eligible : Result.NotEligible);
    }
  }, [eligible]);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="Analyzing Student Loans..." progress={progress} />
    </div>
  );
};

export default Analyzing;
